import React from 'react'

const Logout = () => {
  return (
<>

</>
  )
}

export default Logout
